import React from 'react';
import { navigate } from 'gatsby';

import Layout from '../../components/MenuLayout';
import { CaseEdit } from '../../components/Case/CaseEdit';

export default ({ caseId }) => {
  const onCompleted = (uuid) => {
    navigate(`/case/${uuid}`);
  };

  return (
    <Layout>
      <CaseEdit caseId={caseId} handleCompleted={onCompleted} />
    </Layout>
  );
};
