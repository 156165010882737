import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { Close } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import { useSelector } from 'react-redux';
import { AlertUI } from '../../componentsUI/Alert';
import { GET_CASES, GET_MEDICAL_CASE_FOR_EDITING } from '../../graphql/queries';
import { UPDATE_MEDICAL_CASE } from '../../graphql/mutations';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../componentsUI/SectionBar';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { CaseForm } from './CaseForm';
import useStyles from '../../components/Case/styled/CaseFormMakeStyles';
import { isEmpty } from '../../utils/ObjectUtils';
import Loading from '../Common/Loading';

export const CaseEdit = ({ caseId, handleCompleted }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const { loading, data } = useQuery(
    GET_MEDICAL_CASE_FOR_EDITING,
    {
      variables: {
        uuid: caseId,
      },
    },
  );

  const [serverError, setServerError] = useState(false);
  const [updateMedicalCase, { loading: updating }] = useMutation(UPDATE_MEDICAL_CASE, {
    onCompleted({ updateMedicalCase: updatedMedicalCase }) {
      handleCompleted(updatedMedicalCase.uuid);
    },
    refetchQueries: [{ query: GET_CASES, variables: { hospitalUuid } }],
    awaitRefetchQueries: true,
    onError: (error) => {
      if (error && error.graphQLErrors && error.graphQLErrors.length) {
        setServerError(error.graphQLErrors[0].message);
      }
    },
  });

  const goToCases = () => navigate('/cases');

  const backButton = { label: t('return'), icon: Close, onClick: goToCases };

  const submitAction = async (values) => {
    updateMedicalCase({
      variables: {
        medicalCase: {
          uuid: caseId,
          title: values.title,
          specialty: values.specialty,
          subspecialty: values.subspecialty,
          active: values.active === 'true',
          description: values.description,
        },
      },
    }).then();
  };

  const showServerError = () => {
    const message = serverError.includes('You are not allowed to perform this action')
      ? t('not.allowed.to.perform.action')
      : t('server.error');
    return <AlertUI severity="error" title="Error">{t(message)}</AlertUI>;
  };

  const patientInfo = data && data.medicalCase && data.medicalCase.patient
    && `${data.medicalCase.patient.name} ${data.medicalCase.patient.surname}`;

  const initialValues = data && data.medicalCase && {
    patient: patientInfo,
    title: data.medicalCase.title,
    specialty: data.medicalCase.specialty,
    subspecialty: data.medicalCase.subspecialty,
    active: data.medicalCase.active === 0 ? 'false' : 'true',
    description: data.medicalCase.description,
    // share: '',
    // sharePermission: 'view',
  };

  const formId = 'edit-case-form';

  const buttons = [
    { classes: classes.button, color: 'primary', variant: 'outlined', onClick: goToCases, label: t('cancel') },
    { classes: classes.button, color: 'primary', variant: 'contained', type: 'submit', form: formId, label: t('update') },
  ];

  if (loading || isEmpty(data)) {
    return <Loading />;
  }

  return (
    <>
      <Navbar className={classes.subheader}>
        <SectionBar title="edit.case" back={backButton} />
      </Navbar>
      <Box className={classes.container}>
        <ScrollableContainer>
          <Paper elevation={2} className={classes.wrapper}>
            <CaseForm
              formId={formId}
              initialValues={initialValues}
              buttons={buttons}
              submitAction={submitAction}
              serverError={serverError}
              setServerError={setServerError}
              submitting={updating}
              onClose={goToCases}
              showServerError={showServerError}
            />
          </Paper>
        </ScrollableContainer>
      </Box>
    </>
  );
};
